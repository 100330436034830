import { Component, ViewChild, OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { UserauthApiService } from 'src/app/api/services/userauth-api.service';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { StateService } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserAuthService } from 'src/app/auth/services/user-auth.service';

@Component({
  selector: 'user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  username;
  user: any = {};
  userPassword: any = {};
  loading = false;
  formError = null;
  apiError = null;
  activationLink: string;

  isUserAdmin = this.userStateService.isUserAdmin();
  showChangePassword = false;
  isRiseAuthUser = this.userStateService.isRiseAuthUser();
  editingYourself;

  @ViewChild('userForm')
  public userForm: NgForm;

  @ViewChild('activationLinkText')
  private activationLinkText: ElementRef;

  constructor(private stateService: StateService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private userAuthService: UserAuthService,
    private userApiService: UserApiService,
    private userauth: UserauthApiService,
    private tracker: TrackerService,
    private modalService: ModalService,
    private processErrorCode: ProcessErrorCode,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.username = this.stateService.params.username || this.userStateService.getUsername();
    this.editingYourself = this.userStateService.checkUsername(this.username);

    this.loading = true;
    this.userApiService.get(this.username)
      .then((user) => {
        this.user = user;
      })
      .catch((error) => {
        this._showErrorMessage('load', error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  deleteUser() {
    this.modalService.confirmDanger('Deleting User', 'Are you sure you want to delete this user?', 'Delete Forever')
      .then(() => {
        this.loading = true;

        this.userApiService.delete(this.username)
          .then(() => {
            this.tracker.userEvent('User Deleted', this.userStateService.getUsername(), this.userStateService.checkUsername(this.username), {
              deletedUserId: this.username
            });

            if (this.userStateService.checkUsername(this.username)) {
              this.userAuthService.signOut();
            }

            this.stateService.go('apps.user.list');
          })
          .catch((error) => {
            this._showErrorMessage('delete', error);
          })
          .finally(() => {
            this.loading = false;
          });
      });
  }

  _setPasswordError(error: any) {
    if (this.userForm.controls['currentPassword']) {
      this.userForm.controls.currentPassword.setErrors(error);
    }
  }

  save() {
    if (this.showChangePassword) {
      this._setPasswordError(null);
    }

    if (this.userForm.valid) {
      var changePasswordPromise = Promise.resolve();

      this.loading = true;

      if (this.showChangePassword) {
        changePasswordPromise = this.userauth.updatePassword(
          this.username,
          this.userPassword.currentPassword,
          this.userPassword.newPassword);
        changePasswordPromise
          .then(() => {
            this.userPassword = {};
            this.showChangePassword = false;
          })
          .catch((err) => {
            var newError = err.result.error;

            if (newError.code === 409) {
              this._setPasswordError({'currentPasswordNotValid': true});

              newError.changePassword = true;
            }
            return Promise.reject(newError);
          });
      }

      changePasswordPromise
        .then(() => {
          return this.userApiService.update(this.username, this.user, this.userStateService.isUserAdmin());
        })
        .then((resp) => {
          if (this.userStateService.checkUsername(this.username)) {
            this.userStateService.updateUserProfile(resp.item);
          }
        })
        .catch((error) => {
          this._showErrorMessage('update', error);

          error = (error.result && error.result.error) || error;
          console.debug(error);
          if (error.changePassword) {
            this.apiError = error.message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  toggleChangePassword() {
    this.showChangePassword = !this.showChangePassword;

    if (this.showChangePassword) {
      this._setPasswordError(null);
    }
  }

  currentPasswordUpdated() {
    if (this.userForm.controls['currentPassword'] &&
      this.userForm.controls.currentPassword.errors &&
      this.userForm.controls.currentPassword.errors.currentPasswordNotValid) {
      this._setPasswordError(null);
    }
  }

  createActivationLink() {
    const token = new TextEncoder().encode(this.user.id + new Date(this.user.creationDate).getTime());

    crypto.subtle.digest('SHA-1', token).then((digest) => {
      const url = window.document.location.protocol + "//" + window.document.location.host + '/joinaccount';
      const companyName = encodeURIComponent(this.companyStateService.getCopyOfSelectedCompany().name);
      const userId = encodeURIComponent(this.user.id);
      const hex = [...new Uint8Array(digest)].map((byte) => byte.toString(16).padStart(2, '0')).join('');

      this.activationLink = `${url}/${companyName}/${userId}/${hex}`;
      this.changeDetectorRef.detectChanges();
      this.activationLinkText.nativeElement.select();
    });
  }

  _showErrorMessage(action, error) {
    this.formError = 'Failed to ' + action + ' User.';
    this.apiError = this.processErrorCode(error);
  }

}
