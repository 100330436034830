import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { BigQueryLogging, Loading } from 'src/app/ajs-upgraded-providers';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { UserStateService } from './user-state.service';
import { RvTokenStoreService } from './rv-token-store.service';
import { GoogleAuthService } from './google-auth.service';
import { ObjectHelperService } from 'src/app/shared/services/object-helper.service';
import { CustomAuthService } from './custom-auth.service';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';
import { ApiUtilsService } from 'src/app/api/services/api-utils.service';
import { VisibilityService } from './visibility.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  FORCE_GOOGLE_AUTH = false;

  _state = this.userStateService._state;

  _processVisibilityEvents;

  _authorizeDeferred;
  _authenticateDeferred;

  _shouldLogPageLoad = true;

  constructor(
    private stateService: StateService,
    private $loading: Loading,
    private promiseUtilsService: PromiseUtilsService,
    private apiUtilsService: ApiUtilsService,
    private rvTokenStoreService: RvTokenStoreService,
    private objectHelperService: ObjectHelperService,
    visibilityService: VisibilityService,
    private bigQueryLogging: BigQueryLogging,
    private broadcasterService: BroadcasterService,
    private userStateService: UserStateService,
    private googleAuthService: GoogleAuthService,
    private customAuthService: CustomAuthService
  ) {

    visibilityService.visibilityChange$.subscribe((visible) => {
      console.debug('visibility: ' + document.visibilityState);

      if (this._processVisibilityEvents && visible) {
        this._detectUserOrAuthChange();
      }
    });
  }

  _logPageLoad (details) {
    if (this._shouldLogPageLoad) {
      this._shouldLogPageLoad = false;
      try {
        var duration = new Date().getTime() - window.performance.timing.navigationStart;
        this.bigQueryLogging.logEvent('page load time', details, duration);
      } catch (e) {
        console.debug('Error logging load time');
      }
    }
  };

  _setUserToken (userToken) {
    this._state.userToken = userToken;
    this.rvTokenStoreService.write(this._state.userToken);
  };

  _deleteUserToken (userToken?) {
    delete this._state.userToken;
    this.rvTokenStoreService.clear();
  };

  _cancelAccessTokenAutoRefresh () {};

  _resetUserState () {
    var promise = Promise.resolve();

    if (!this.userStateService.isRiseAuthUser()) {
      promise = this.googleAuthService.signOut();
    }

    console.debug('Clearing user token...');
    this._cancelAccessTokenAutoRefresh();

    this._deleteUserToken();

    this.userStateService._resetState();

    return promise;
  };

  _clearAnalytics () {
    try {
      if ((window as any).analytics) {
        (window as any).analytics.user().traits({});
      }
    } catch (e) {
      console.debug('Error clearing analytics: ', e);
    } finally {
      return Promise.resolve();
    }
  };

  _reloadPage() {
    window.location.reload();
  }

  _detectUserOrAuthChange () {
    var token = this.rvTokenStoreService.read();
    if (token && !angular.equals(token, this._state.userToken)) {
      console.error('Authentication Failed. User token no longer matches stored token.');

      //token change indicates that user either signed in, or signed out, or changed account in other app
      this._reloadPage();
    } else if (this._state.userToken) {
      this._authenticateDeferred = null;

      // make sure user is not signed out
      this.authenticate().finally(() => {
        if (!this._state.userToken) {
          console.error('Authentication Failed. User no longer signed in.');

          this._reloadPage();
        }
      });
    }
  };

  addEventListenerVisibilityAPI () {
    this._processVisibilityEvents = true;
  };

  removeEventListenerVisibilityAPI () {
    this._processVisibilityEvents = false;
  };

  /*
   * Responsible for triggering the Google OAuth process.
   *
   */
  _authorize (authenticatedUser) {
    if (this._authorizeDeferred) {
      return this._authorizeDeferred.promise;
    }

    if (authenticatedUser) {
      if (!this._state.user.username || !this._state.profile.username ||
        this._state.user.username !== authenticatedUser.email) {
        this._authorizeDeferred = this.promiseUtilsService.generateDeferredPromise();

        //populate user
        this.objectHelperService.clearAndCopy({
          userId: authenticatedUser
            .id, //TODO: ideally we should not use real user ID or email, but use hash value instead
          username: authenticatedUser.email,
          picture: authenticatedUser.picture
        }, this._state.user);

        this._setUserToken(authenticatedUser);

        this.userStateService.refreshProfile()
          .catch(e => {
            let err = this.apiUtilsService.getError(e);
            if (err && (err.code !== 403 || (err.message && err.message.indexOf('feature is not available') !== -1))) {
              this._authorizeDeferred.reject(err);

              this._authorizeDeferred = undefined;

              return Promise.reject();
            }
          })
          .then(() => {
            this._authorizeDeferred.resolve();

            this.broadcasterService.emit('risevision.user.authorized');

            this._authorizeDeferred = undefined;
          });

        return this._authorizeDeferred.promise;
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.reject('No user');
    }
  };

  authenticate (forceAuth?) {
    var authenticateDeferred;
    var authenticationPromise,
      isRiseAuthUser = false;

    // Clear User state
    if (forceAuth) {
      this._authenticateDeferred = null;
    }

    // Return cached promise
    if (this._authenticateDeferred) {
      return this._authenticateDeferred.promise;
    } else {
      this._authenticateDeferred = this.promiseUtilsService.generateDeferredPromise();
    }

    // Always resolve local copy of promise
    // in case cached version is cleared
    authenticateDeferred = this._authenticateDeferred;
    console.debug('authentication called');

    if (forceAuth) {
      this.$loading.startGlobal('risevision.user.authenticate');
    }

    // Check for Token
    if (this._state.userToken && this._state.userToken.token && !this.FORCE_GOOGLE_AUTH) {
      isRiseAuthUser = true;
      authenticationPromise = this.customAuthService.authenticate();
    } else {
      authenticationPromise = this.googleAuthService.authenticate();
    }

    authenticationPromise
      .then(this._authorize.bind(this))
      .then(() => {
        this.userStateService._setIsRiseAuthUser(isRiseAuthUser);
        authenticateDeferred.resolve();
      })
      .catch(err => {
        console.debug('Authentication Error: ', err);

        this._resetUserState();

        authenticateDeferred.reject(err);
      })
      .finally(() => {
        this.addEventListenerVisibilityAPI();

        this.$loading.stopGlobal('risevision.user.authenticate');

        this._logPageLoad('authenticated user');
      });

    return authenticateDeferred.promise;
  };

  signOut () {
    this._authenticateDeferred = null;
    var joinaccountState = this.userStateService._state.joinaccountState;

    return this._resetUserState()
      .then(() => {
        this._clearAnalytics();
      })
      .finally(() => {
        this.broadcasterService.emit('risevision.user.signedOut');
        console.debug('User is signed out.');

        if (joinaccountState) {
          this.stateService.go('apps.auth.joinaccount', joinaccountState);
        }
      });
  };

  setLoginMethod (method) {
    this.userStateService.setLoginMethod(method);
  };

}

angular.module('risevision.apps.services')
  .factory('userAuthFactory', downgradeInjectable(UserAuthService));