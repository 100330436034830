import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  visibilityChange$: Observable<boolean>;

  constructor() {
    this.visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(
      map(() => document.visibilityState === 'visible')
    );
  }
}