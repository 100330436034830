import { StatesModule, Transition } from '@uirouter/angular';

import { UrlStateService } from '../ajs-upgraded-providers';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { LoginComponent } from './components/login/login.component';

import { RegistrationComponent } from './components/registration/registration.component';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { ResetPasswordConfirmComponent } from './components/reset-password-confirm/reset-password-confirm.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { RegistrationService } from './services/registration.service';
import { UserStateService } from './services/user-state.service';
import { RvshareAppDetectionService } from '../screen-sharing/services/rvshare-app-detection.service';
import { UserAuthService } from './services/user-auth.service';

export const routes: StatesModule = {
  states: [

    {
      name: 'apps.auth.unauthorized',
      url: '/unauthorized/:state',
      component: LoginComponent,
      data: {
        rvshareAppEnabled: true,
      },
      params: {
        passwordReset: null,
        authError: null,
        state: ''
      }
    },

    {
      name: 'apps.auth.createaccount',
      url: '/createaccount/:state',
      component: CreateAccountComponent,
      params: {
        joinAccount: false,
        state: ''
      }
    },

    {
      name: 'apps.auth.joinaccount',
      url: '/joinaccount/:companyName/:email/:hash',
      component: CreateAccountComponent,
      params: {
        joinAccount: true,
        companyName: '',
        email: { squash: true, value: '' },
        hash: { squash: true, value: '' }
      }
    },

    {
      name: 'apps.auth.unregistered',
      url: '/unregistered/:state',
      params: {
        state: ''
      },
      component: RegistrationComponent,
      resolve: [{
        token: 'authenticate',
        deps: [Transition, UserStateService, UserAuthService, RegistrationService, UrlStateService],
        resolveFn: (transition, userStateService, userAuthService, registrationService, urlStateService) => {
          return userAuthService.authenticate(false)
            .then(() => {
              if (!userStateService.isRiseVisionUser()) {
                registrationService.init();
              } else {
                urlStateService.redirectToState(transition.params().state);
              }
            })
            .catch(() => {
              transition.router.stateService.go('apps.auth.unauthorized', transition.params());
            });
        }
      }]
    },
    {
      name: 'apps.auth.requestpasswordreset',
      url: '/requestpasswordreset',
      component: RequestPasswordResetComponent,
      data: {
        rvshareAppEnabled: true,
      }
    },
    {
      name: 'apps.auth.resetpassword',
      url: '/resetpassword/:user',
      data: {
        rvshareAppEnabled: true,
      },
      params: {
        user: '',
        token: ''
      },
      component: ResetPasswordConfirmComponent
    },
    {
      name: 'apps.auth.unsubscribe',
      url: '/unsubscribe?email&id&name',
      params: {
        email: '',
        id: '',
        name: ''
      },
      component: UnsubscribeComponent
    },
    {
      name: 'apps.auth.confirmaccount',
      url: '/confirmaccount/:user/:token',
      params: {
        email: '',
        id: '',
        name: ''
      },
      data: {
        rvshareAppEnabled: true,
      },
      component: LoginComponent
    }
  ]
};